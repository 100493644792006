import {
  IAggregateSaleData,
  IAggregateSaleDataParams,
  IAggregateSaleContrastData,
  IAggregateSupplierContrastData,
  IAggregateSaleTrendParams,
  IAggregateSaleTrend,
  ISuppliertHour,
  ISupplierContrastHourParams,
  ILastHourFeed,
  IHourFeed,
  IFeedForHour
} from './type';
import hyRequest from '@/service';

enum DashboardApi {
  getAggregateSaleData = '/purchaseData/aggregatePurchaseData',
  getAggregateSaleContrastData = '/purchaseData/aggregatePurchaseContrastData',
  getAggregateSupplierContrast = '/purchaseData/aggregateSupplierContrast',
  getAggregateSaleTrend = '/purchaseData/aggregatePurchaseTrend',
  getAggregateSupplierContrastHour = '/purchaseData/aggregateSupplierContrastHour',
  findLastHourFeed = '/purchaseData/findLastHourFeed',
  getHourFeed = '/purchaseData/hourFeed',
  findFeedForHour = '/purchaseData/findFeedForHour'
}

// 骨料汇总及各料类销售数据
export function getAggregateSaleData(data: IAggregateSaleDataParams) {
  return hyRequest.post<IAggregateSaleData>({
    url: DashboardApi.getAggregateSaleData,
    data
  });
}

//骨料销售对比数据
export function getAggregateSaleContrastData(data: IAggregateSaleDataParams) {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSaleContrastData,
    data
  });
}

//供应商料类采购排行榜
export function getAggregateSupplierContrast(data: IAggregateSaleDataParams) {
  return hyRequest.post<IAggregateSupplierContrastData>({
    url: DashboardApi.getAggregateSupplierContrast,
    data
  });
}
//骨料销售趋势数据
export function getAggregateSaleTrend(data: IAggregateSaleTrendParams) {
  return hyRequest.post<IAggregateSaleTrend>({
    url: DashboardApi.getAggregateSaleTrend,
    data: data
  });
}

//供应商料类采购小时趋势
export function getAggregateSupplierContrastHour(
  data: ISupplierContrastHourParams
) {
  return hyRequest.post<ISuppliertHour>({
    url: DashboardApi.getAggregateSupplierContrastHour,
    data: data
  });
}

//上一小时各供应商进料情况

export function findLastHourFeed() {
  return hyRequest.post<ILastHourFeed>({
    url: DashboardApi.findLastHourFeed
  });
}

//各小时进料情况
export function getHourFeed(params: IAggregateSaleDataParams) {
  return hyRequest.post<IHourFeed>({
    url: DashboardApi.getHourFeed,
    data: params
  });
}

//根据日期和小时查询供应商原石进料情况
export function findFeedForHour(params: { date: string; hour: string }) {
  return hyRequest.post<IFeedForHour>({
    url: DashboardApi.findFeedForHour,
    data: params
  });
}
