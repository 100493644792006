import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9948a91e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "report-detail"
};
var _hoisted_2 = {
  class: "container"
};
var _hoisted_3 = {
  class: "total flex align-center justify-between"
};
var _hoisted_4 = {
  class: "rankList"
};
var _hoisted_5 = {
  key: 1,
  class: "box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_c_type_tab = _resolveComponent("c-type-tab");
  var _component_c_date_header_with_btn = _resolveComponent("c-date-header-with-btn");
  var _component_list_item = _resolveComponent("list-item");
  var _component_van_collapse = _resolveComponent("van-collapse");
  var _component_van_list = _resolveComponent("van-list");
  var _component_c_no_data = _resolveComponent("c-no-data");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_c_type_tab, {
    onConfirmType: _ctx.confirmType
  }, null, 8, ["onConfirmType"]), _createVNode(_component_c_date_header_with_btn, {
    ref: "dateRef",
    showBtn: false,
    type: _ctx.params.type,
    onConfirmDate: _ctx.confrimDate
  }, null, 8, ["type", "onConfirmDate"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, "单数：" + _toDisplayString(_ctx.totalData.tot_cnt), 1), _createElementVNode("span", null, "金额(万元)：" + _toDisplayString(_ctx.totalData.tot_money ? _ctx.totalData.tot_money : 0), 1)]), _createElementVNode("div", _hoisted_4, [_ctx.detailData.length ? (_openBlock(), _createBlock(_component_van_list, {
    key: 0,
    loading: _ctx.loading,
    "onUpdate:loading": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.loading = $event;
    }),
    finished: _ctx.finished,
    "finished-text": "没有更多了",
    onLoad: _ctx.onLoad
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_collapse, {
        modelValue: _ctx.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.activeName = $event;
        }),
        accordion: "",
        onChange: _ctx.collapseChange
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailData, function (item, index) {
            return _openBlock(), _createBlock(_component_list_item, {
              key: index,
              index: index,
              item: item,
              detailList: _ctx.listData
            }, null, 8, ["index", "item", "detailList"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["modelValue", "onChange"])];
    }),
    _: 1
  }, 8, ["loading", "finished", "onLoad"])) : _ctx.finished && !_ctx.detailData.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_c_no_data)])) : _createCommentVNode("", true)])])]);
}