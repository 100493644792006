import {
  IAggregateSaleData,
  IAggregateSaleDataParams,
  IAggregateSaleContrastData,
  IAggregateSaleTrendParams,
  IAggregateSaleTrend
} from './type';
import hyRequest from '@/service';

enum DashboardApi {
  getAggregateSaleData = '/purchaseData/mortarPurchaseData',
  getAggregateSaleContrastData = '/purchaseData/mortarPurchaseContrastData',
  getAggregateSaleTrend = '/purchaseData/mortarPurchaseTrend'
}

// 骨料汇总及各料类销售数据
export function getAggregateSaleData(data: IAggregateSaleDataParams) {
  return hyRequest.post<IAggregateSaleData>({
    url: DashboardApi.getAggregateSaleData,
    data
  });
}

//骨料销售对比数据
export function getAggregateSaleContrastData(data: IAggregateSaleDataParams) {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSaleContrastData,
    data
  });
}
//骨料销售趋势数据
export function getAggregateSaleTrend(data: IAggregateSaleTrendParams) {
  return hyRequest.post<IAggregateSaleTrend>({
    url: DashboardApi.getAggregateSaleTrend,
    data: data
  });
}
