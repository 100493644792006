import hyRequest from '@/service';
import {
  ITotalData,
  ITrendData,
  ISparePartsDetail,
  ISparePartsInfo,
  ISparePartsTotal
} from '@/service/purchase/spareParts/type';
enum DashboardApi {
  findSparePartsTrend = '/purchaseData/findSparePartsTrend',
  findSparePartsCountByDateType = '/purchaseData/findSparePartsCountByDateType',
  findSparePartsDetailByPage = '/purchaseData/findSparePartsDetailByPage',
  findSparePartsDetailInfo = '/purchaseData/findSparePartsDetailInfo',
  findSparePartsTotal = '/purchaseData/findSparePartsDetailStatistics'
}

export function findSparePartsTrend(data: {
  type: number;
  date: string;
  rangeType: number;
  rangeNum: number;
}) {
  return hyRequest.post<ITrendData>({
    url: DashboardApi.findSparePartsTrend,
    data
  });
}

//按时间类型查询备品备件统计
export function findSparePartsCountByDateType(data: {
  type: number;
  date: string;
}) {
  return hyRequest.post<ITotalData>({
    url: DashboardApi.findSparePartsCountByDateType,
    data
  });
}

// 备品备件明细分页列表
export function findSparePartsDetailByPage(data: {
  type: number;
  date: string;
  pageNumber: number;
  pageSize: number;
}) {
  return hyRequest.post<ISparePartsDetail>({
    url: DashboardApi.findSparePartsDetailByPage,
    data
  });
}

// 备品备件明细下拉
export function findSparePartsDetailInfo(data: {
  type: number;
  date: string;
  stoneName: string;
}) {
  return hyRequest.post<{ list: ISparePartsInfo[] }>({
    url: DashboardApi.findSparePartsDetailInfo,
    data
  });
}

//备品备件明细统计
export function findSparePartsTotal(data: { type: number; date: string }) {
  return hyRequest.post<ISparePartsTotal>({
    url: DashboardApi.findSparePartsTotal,
    data
  });
}
