import hyRequest from '@/service';
import { IDetailParams, IPurchaseDetailData } from './type';

enum DashboardApi {
  getAggregatePurchaseDetail = '/purchaseData/aggregatePurchaseDetail',
  getCommercialPurchaseDetail = '/purchaseData/commercialPurchaseDetail',
  getMortarPurchaseDetail = '/purchaseData/mortarPurchaseDetail'
}

// 查询骨料采购明细
export function getAggregatePurchaseDetail(data: IDetailParams) {
  return hyRequest.post<IPurchaseDetailData>({
    url: DashboardApi.getAggregatePurchaseDetail,
    data
  });
}
// 查询商砼采购明细
export function getCommercialPurchaseDetail(data: IDetailParams) {
  return hyRequest.post<IPurchaseDetailData>({
    url: DashboardApi.getCommercialPurchaseDetail,
    data
  });
}
// 查询砂浆采购明细
export function getMortarPurchaseDetail(data: IDetailParams) {
  return hyRequest.post<IPurchaseDetailData>({
    url: DashboardApi.getMortarPurchaseDetail,
    data
  });
}
