/* unplugin-vue-components disabled */import _slicedToArray from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.number.constructor.js";
import { useEmptyStringFormat } from '@/hooks/fn';
import localCache from '@/utils/localCache';
import CTypeTab from '@/components/CTypeTab.vue';
import useDetailTotal from './hooks/useDetailTotal';
import useSparePartsDetail from './hooks/useSparePartsDetail';
import useSparePartsDetailList from './hooks/useSparePartsDetailList';
import ListItem from './cpns/ListItem.vue';
import CNoData from '@/components/CNoData.vue';
export default defineComponent({
  name: 'SpartPartsDetail',
  components: {
    CTypeTab: CTypeTab,
    ListItem: ListItem,
    CNoData: CNoData
  },
  setup: function setup() {
    var emptyStringFormat = useEmptyStringFormat();
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    var route = useRoute();
    var dateType = ref(0);
    var params = ref({
      date: dayjs().format('YYYY/MM/DD'),
      type: 0,
      pageNumber: 1,
      pageSize: 10
    });
    var stoneName = ref('');
    var listParams = computed(function () {
      return {
        date: params.value.date,
        type: params.value.type,
        stoneName: stoneName.value
      };
    });
    if (route.params.type) {
      localCache.setItem('defaultDateType', route.params.type);
    }
    var localCacheDateType = localCache.getItem('defaultDateType');
    if (localCacheDateType) {
      dateType.value = Number(localCacheDateType);
    }
    if (dateType.value) {
      params.value.type = dateType.value;
      if (dateType.value === 4) {
        params.value.date = dayjs().format('YYYY/MM/DD') + ',' + dayjs().format('YYYY/MM/DD');
      } else {
        params.value.date = dayjs().format('YYYY/MM/DD');
      }
    }
    provide('defaultDateType', readonly(dateType));
    onBeforeUnmount(function () {
      localCache.deleteItem('defaultDateType');
    });
    var confrimDate = function confrimDate(val) {
      params.value.date = val;
      params.value.pageNumber = 1;
      detailData.value = [];
      listData.value = [];
      finished.value = false;
      loading.value = false;
      findSparePartsTotalAsync();
      //获取分页列表
      findSparePartsDetailByPageAsync();
    };
    var dateRef = ref();
    var confirmType = function confirmType(val) {
      var _dateRef$value;
      activeName.value = undefined;
      params.value.type = val;
      //把所以日期控件的日期变为当前最新日期
      (_dateRef$value = dateRef.value) === null || _dateRef$value === void 0 || _dateRef$value.reset();
      if (val === 4) {
        params.value.date = dayjs().format('YYYY/MM/DD') + ',' + dayjs().format('YYYY/MM/DD');
      } else {
        params.value.date = dayjs().format('YYYY/MM/DD');
      }
      params.value.pageNumber = 1;
      detailData.value = [];
      listData.value = [];
      finished.value = false;
      loading.value = false;
      findSparePartsTotalAsync();
      //获取分页列表
      findSparePartsDetailByPageAsync();
    };
    var totalParams = computed(function () {
      return {
        date: params.value.date,
        type: params.value.type
      };
    });
    var _useDetailTotal = useDetailTotal(totalParams),
      _useDetailTotal2 = _slicedToArray(_useDetailTotal, 2),
      totalData = _useDetailTotal2[0],
      findSparePartsTotalAsync = _useDetailTotal2[1];
    var activeName = ref();
    var _useSparePartsDetail = useSparePartsDetail(params),
      _useSparePartsDetail2 = _slicedToArray(_useSparePartsDetail, 5),
      detailData = _useSparePartsDetail2[0],
      loading = _useSparePartsDetail2[1],
      finished = _useSparePartsDetail2[2],
      findSparePartsDetailByPageAsync = _useSparePartsDetail2[3],
      onLoad = _useSparePartsDetail2[4];
    var _useSparePartsDetailL = useSparePartsDetailList(listParams),
      _useSparePartsDetailL2 = _slicedToArray(_useSparePartsDetailL, 2),
      listData = _useSparePartsDetailL2[0],
      findSparePartsDetailInfoAsync = _useSparePartsDetailL2[1];
    onMounted(function () {
      //获取合计
      findSparePartsTotalAsync();
      //获取分页列表
      findSparePartsDetailByPageAsync();
    });
    var collapseChange = function collapseChange(val) {
      stoneName.value = val;
      listData.value = [];
      findSparePartsDetailInfoAsync();
    };
    return {
      params: params,
      confirmType: confirmType,
      confrimDate: confrimDate,
      detailData: detailData,
      loading: loading,
      finished: finished,
      onLoad: onLoad,
      activeName: activeName,
      dateType: dateType,
      totalData: totalData,
      formatDate: formatDate,
      dateRef: dateRef,
      listData: listData,
      collapseChange: collapseChange,
      emptyStringFormat: emptyStringFormat
    };
  }
});